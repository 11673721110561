import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Meta/seo"
import { ContentWrapper } from "../components/ContentWrapper"
import { HeaderImageContainer } from "../components/HeaderImageContainer"
import Img from "gatsby-image"
import { Style } from "../style"

interface Props {
  data: {
    markdownRemark: {
      html: string
    }
    file: {
      childImageSharp: {
        fluid: any
      }
    }
  }
}

const IndexPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <HeaderImageContainer>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="An abstract photograph of some architecture."
          fadeIn={false}
          backgroundColor={Style.color.brand}
          loading="eager"
        />
      </HeaderImageContainer>
      <ContentWrapper>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </ContentWrapper>
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    markdownRemark(frontmatter: { slug: { eq: "index" } }) {
      html
    }
    file(relativePath: { eq: "home-header.jpeg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          src
          srcSet
          sizes
          srcWebp
          srcSetWebp
        }
      }
    }
  }
`

export default IndexPage
